<template>
   <svg 
       :width="computeWidth()" 
       :height="computeHeight()" 
       :style="{ 'fill': fill }"
   >
       <use :xlink:href="`/assets/sprite.svg#${iconName}`"></use>
   </svg>
</template>

<script>
   const requireAll = (requireContext) => requireContext.keys().map(requireContext);
   const req = require.context("@/main/resources/static/icons", false, /\.svg$/);
   requireAll(req);

   export default {
       props: {
           iconName: {
               type: String,
               required: true
           },
           width: {
               type: Number,
               requred: false,
               default: 24
           },
           height: {
               type: Number,
               required: false,
               default: 24
           },
           size: {
               type: Number,
               required: false,
               default: null
           },
           fill: {
               type: String,
               required: false
           }
       },
       mounted() {
       },
       methods: {
           computeWidth() {
               return this.size !== null ? this.size  : this.width;
           },
           computeHeight() {
               return this.size  !== null ? this.size  : this.height;
           }
       }
   }
</script>

<style>
</style>