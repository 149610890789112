import Vue from 'vue';
import App from './App.vue';
import router from '@/main/resources/static/scripts/router';
import store from '@/main/resources/static/scripts/store';
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import VueCookies from 'vue-cookies';
import TextareaAutosize from 'vue-textarea-autosize';
import moment from 'moment';
moment.locale('ru');
Vue.config.productionTip = false;
Vue.use(BootstrapVue, {
    // Конфигурация компонентов
    BFormFile: {
        placeholder: 'Файлы не выбраны',
        browseText: 'Обзор'
    },
    BButton: {
        variant: 'primary'
    }
});
Vue.use(BootstrapVueIcons);
Vue.use(VueCookies);
Vue.use(TextareaAutosize);
Vue.mixin({
    methods: {
        getOrDash(obj) {
            return obj || '-';
        },
        getNameOrDash(obj) {
            return obj == null ? '-' : obj.name;
        },
        getDisplayNameOrDash(obj) {
            return obj == null ? '-' : obj.displayName;
        },
        getValueOrDash(obj) {
            return obj == null ? '-' : obj.value;
        },
        getYesOrNo(obj) {
            return obj == true ? 'Да' : 'Нет';
        }
    }
});
Vue.$cookies.config('7d');
const vueApp = new Vue({
    router,
    store,
    render: h => h(App)
});
vueApp.$mount('#app');
