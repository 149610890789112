<template>
  <div class="row page-container">
    <div class="col-xl-6 col-lg-6">
      <div class="row" style="margin-top: 16px;">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 flex-center">
          <MainLogo style="height: 50px;"/>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-9 col-lg-9 flex-center">
          <span class="Honey" :style="getFontSize(32)">Подсобное хозяйство Тетериных</span>
        </div>
      </div>
      <div class="row" style="margin-top: 8px; gap: 8px;">
        <router-link :to="{ name: 'index' }">
          <button type="button" class="btn btn-primary btn-lg">
            <YIcon iconName="icon-home" class="white-svg"></YIcon>
            Главная
          </button>
        </router-link>
        <router-link :to="{ name: 'products' }">
          <button type="button" class="btn btn-primary btn-lg">
            <YIcon iconName="icon-products" class="white-svg"></YIcon>
            Продукты
          </button>
        </router-link>
      </div>
      <hr>
      <p>Пасека располагается в <b>д. Горинское</b> - Тутаевский район Ярославской области. </p>
      <p>Совокупность чистой природы, удаленности от трассы и богатого разнообразия трав помогают создавать пчелам
        <b>вкусный, полезный и ароматный мед.</b>
      </p>
      <p>А <b>наша основная задача</b> - относиться к пчелам с уважением, следить за их здоровьем и делать все
        возможное, чтобы окружающая пасеку природа развивалась.</p>
      <p>Помимо мёда мы так же предлагаем <b>прополис, пыльцу, воск, пергу</b> и другие продукты пчеловодства. <br>
        ..и не только пчеловодства: <b>чаи из сборов местных трав и ягод</b>, а также настойки на <b>пчелиной
          огневке</b> и <b>пчелином подморе</b>.</p>

      <p>Пчеловод - <b>Алексей Николаевич Тетерин</b>. Связаться можно по телефону <a href="tel:+79159700770">+7 915 970
          0770</a></p>

      <span class="flex-right"><em>«Самая чистая радость — <br> радость природы» <b>Л.Н. Толстой</b></em></span>
      <hr>
      <div @click="mapTouchMake();">
        <iframe :style="computedTouch ? '' : 'pointer-events:none;'"
          src="https://yandex.ru/map-widget/v1/?um=constructor%3A248b0f0898f3f50a4d9842c177ab8756158c0c7781e62df316df08a2e399cad3&amp;scroll=false&amp;source=constructor"
          width="100%" height="300" frameborder="0">
        </iframe>
      </div>

    </div>
    <div class="col-xl-6 col-lg-6">
      <img :src="require('../static/images/home/image_0.png')" width="100%">
    </div>
  </div>
</template>

<script>
import MainLogo from '@/main/resources/vue/common/MainLogo';
import YIcon from '@/main/resources/vue/common/YIcon'
import styleGenerator from '@/main/resources/vue/mixins/styleGenerator';

export default {
  name: 'HomeView',
  data() {
    return {
      mapTouch: false,
    }
  },
  mixins: [
    styleGenerator
  ],
  components: {
    MainLogo,
    YIcon
  },
  computed: {
    computedTouch: function () {
      return this.mapTouch;
    }
  },
  methods: {
    mapTouchMake: function () {
      this.mapTouch = true;
    }
  }
}
</script>
