import Vue from "vue";
import Vuex from 'vuex';
Vue.use(Vuex);
const store = {
    state() {
        return {};
    },
    getters: {},
    mutations: {}
};
export default new Vuex.Store(store);
