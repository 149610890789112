<template>
  <div class="row page-container">
    <div class="col-xl-6 col-lg-6">
      <div class="row" style="margin-top: 16px;">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 flex-center">
          <MainLogo style="height: 50px;"/>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-9 col-lg-9 flex-center">
          <span class="Honey" :style="getFontSize(32)">Подсобное хозяйство Тетериных</span>
        </div>
      </div>
      <div class="row" style="margin-top: 8px; gap: 8px;">
        <router-link :to="{ name: 'index' }">
          <button type="button" class="btn btn-primary btn-lg">
            <YIcon iconName="icon-home" class="white-svg"></YIcon>
            Главная
          </button>
        </router-link>
        <router-link :to="{ name: 'products' }">
          <button type="button" class="btn btn-primary btn-lg">
            <YIcon iconName="icon-products" class="white-svg"></YIcon>
            Продукты
          </button>
        </router-link>
      </div>
    </div>
    <div class="col-xl-6 col-lg-6"></div>
    <div class="col-12">
      <hr/>
      <div class="card" style="width: 18rem;">
        <img :src="require('../static/images/products/honey.jpg')" class="card-img-top" alt="...">
        <div class="card-body">
          <h5 class="card-title">
            Мёд цветочный
          </h5>
          <p class="card-text">
            Сезонный мёд разливается по фирменным банкам <b>0,65л, 1л</b> или <b>3л</b>.
            Другие объемы по просьбе клиента. Звоните для уточнений, вопросов, заказов :)
          </p>
          <a class="noselect btn flex-center btn-primary" style="pointer-events: none; margin-bottom: 8px;">
            НАЛИЧИЕ УТОЧНЯЙТЕ ПО ТЕЛЕФОНУ (август-сентябрь)
          </a>
          <a href="tel:+79159700770" class="btn flex-center btn-primary">8-915-970-07-70</a>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
import MainLogo from '@/main/resources/vue/common/MainLogo';
import YIcon from '@/main/resources/vue/common/YIcon'
import styleGenerator from '@/main/resources/vue/mixins/styleGenerator';

export default {
  name: 'HomeView',
  data() {
    return {
      mapTouch: false,
    }
  },
  mixins: [
    styleGenerator
  ],
  components: {
    MainLogo,
    YIcon
  },
  computed: {
    computedTouch: function () {
      return this.mapTouch;
    }
  },
  methods: {
    mapTouchMake: function () {
      this.mapTouch = true;
    }
  }
}
</script>
