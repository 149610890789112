export default {
   methods: {
      getStyle () {
         var style = '';
         for (var i = 0; i < arguments.length; i++) {
            style += arguments[i] + ( i != arguments.length - 1 ? '; ' : '');
         }
         style += '';
         return style;
      },
      getFontSize (size) {
         return `
            font-size: ${size}px
         `;
      },
      getGap (size) {
         return `
            gap: ${size}px
         `;
      }
   },
}